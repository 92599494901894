import React from 'react';

const AuthorSchema = ({ author }) => {
    if (!author.display_name || !author.user_nicename || !author?.mysqlImage?.childImageSharp?.fluid?.src) {
        return null;
    }

    const schema = {
        "@context": "https://schema.org",
        "@type": "Person",
        "name": author.display_name,
        "url": `https://fintek.pl/redaktor/${author.user_nicename}/`,
        "image":`https://fintek.pl${author.mysqlImage.childImageSharp.fluid.src}`,
        "jobTitle": "Autor",
        "worksFor": {
            "@type": "Organization",
            "name": "Fintek.pl"
        }
    };
    
    return (
        <script type="application/ld+json">
            {JSON.stringify(schema)}
        </script>
    );
};

export default AuthorSchema;